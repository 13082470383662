import React from 'react';
import Layout from '../components/Layout';
import PodcastAppearances from '../components/contentBlocks/PodcastAppearances';
import Presentations from '../components/contentBlocks/Presentations';

const CommunityConversations = ({ path }) => (
  <Layout title="Community Conversations" path={path}>
    <Presentations />
    <PodcastAppearances />
  </Layout>
);

export default CommunityConversations;
