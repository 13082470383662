import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';
import { colors, breakpoints } from '../../styles/utilities/settings';
import Wrapper from '../../styles/utilities/Wrapper';
import SvgLoader from '../helpers/SvgLoader';
import { button } from '../../styles/utilities/elements';

const PodcastAppearances = () => {
  const [limit, setLimit] = useState(4);
  const {
    allPodcasts: { podcasts },
  } = useStaticQuery(graphql`
    {
      allPodcasts: allSanityPodcastAppearance(
        sort: { fields: publishedAt, order: DESC }
      ) {
        podcasts: nodes {
          id
          title
          date: publishedAt
          description: episodeDescription
          url: podcastUrl
          episode: episodeTitle
        }
      }
    }
  `);
  return (
    <SPodcastAppearances>
      <Wrapper>
        <h2>Podcast Appearances</h2>
        {podcasts
          .slice(0, limit)
          .map(({ id, title, episode, url, date, description }) => (
            <Podcast key={id}>
              <div>
                <SvgLoader name="microphone" color={colors.gold} />
                <div>
                  <a href={url} target="_blank" rel="noreferrer">
                    {title}
                  </a>
                  <p>
                    <strong>{`EPISODE: ${episode}`}</strong>
                    <br />
                    {moment(date).format('LL')}
                  </p>
                </div>
              </div>
              <div>
                <p>{description}</p>
              </div>
            </Podcast>
          ))}
        {limit < podcasts.length && (
          <div className="loadmore">
            <a
              href={null}
              onClick={() => {
                setLimit(limit + 4);
              }}
            >
              Show Older Podcasts
            </a>
          </div>
        )}
      </Wrapper>
    </SPodcastAppearances>
  );
};

export default PodcastAppearances;

const Podcast = styled.div`
  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    display: flex;
    justify-content: space-between;
  }

  > div {
    &:nth-child(1) {
      display: flex;
      align-items: flex-start;
      width: 280px;
      margin-right: 20px;

      svg {
        margin: 4px 15px 0 0;
      }

      a {
        display: block;
        color: ${colors.gold};
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 600;
        font-size: 20px;
      }

      p {
        margin: 0;
      }
    }

    &:nth-child(2) {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        width: calc(100% - 300px);
      }

      @media screen and (max-width: ${breakpoints.ipadPort - 1}px) {
        margin-top: 10px;
        padding-left: 25px;
      }
    }
  }
`;

const SPodcastAppearances = styled.div`
  padding: 100px 0 120px;
  background-color: ${colors.darkerGray};
  color: ${colors.white};

  h2 {
    color: ${colors.gold};
    display: inline-block;
    border-bottom: 4px solid ${colors.gold};
    margin-bottom: 50px;
    padding-bottom: 8px;
    line-height: 1.1;
  }

  ${Podcast} {
    + ${Podcast} {
      margin-top: 50px;
    }
  }

  .loadmore {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 50px;

    a {
      ${button};
    }
  }
`;
